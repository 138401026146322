// Prepare document
(function (document: Document) {
    function prepareControls(
        collection: HTMLCollectionOf<HTMLInputElement>,
        callback: (input: string) => string,
    ): void {
        for (const input of Object.values(collection)) {
            input.oninput = () => {
                if (!input.previousElementSibling) {
                    return;
                }

                const value = new Intl.NumberFormat().format(Number(input.value));
                input.previousElementSibling.innerHTML = callback(value);
            };
        }
    }

    function initialiseUsedCarFinance(): void {
        const mileages = document.getElementsByClassName(
            ' finance-select-mileage',
        ) as HTMLCollectionOf<HTMLInputElement>;
        const deposits = document.getElementsByClassName(
            ' finance-select-deposit',
        ) as HTMLCollectionOf<HTMLInputElement>;
        const terms = document.getElementsByClassName(' finance-select-term') as HTMLCollectionOf<HTMLInputElement>;

        if (mileages.length < 1 || deposits.length < 1 || terms.length < 1) {
            return;
        }

        prepareControls(mileages, (mileage: string) => `${mileage} miles`);
        prepareControls(deposits, (deposit: string) => `£${deposit}`);
        prepareControls(terms, (term: string) => `${term} months`);
    }

    document.addEventListener('DOMContentLoaded', () => {
        initialiseUsedCarFinance();
    });
})(document);
