// Load styles
import '../scss/app.scss';
import '@fortawesome/fontawesome-svg-core/styles.css';

// Load bootstrap
import '@popperjs/core';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
//import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';
//import Popover from 'bootstrap/js/dist/popover';
//import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
//import Toast from 'bootstrap/js/dist/toast';
import Tooltip from 'bootstrap/js/dist/tooltip';

// Load font awesome
import { config, dom, library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import {
    faCar,
    faCarBattery,
    faCarSide,
    faChartLine,
    faCheck,
    faCircleCheck,
    faCircleInfo,
    faClipboardList,
    faEnvelope,
    faGasPump,
    faGaugeHigh,
    faHandHoldingHand,
    faHeart,
    faLandmark,
    faLocationDot,
    faPhone,
    faRoad,
    faRoadCircleCheck,
    faScrewdriverWrench,
    faSterlingSign,
    faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';

// Import icons
config.autoAddCss = false;
library.add(
    // Brands
    faFacebook,
    faYoutube,

    // Solid
    faCar,
    faCarBattery,
    faCarSide,
    faChartLine,
    faCheck,
    faCircleCheck,
    faCircleInfo,
    faClipboardList,
    faEnvelope,
    faGasPump,
    faGaugeHigh,
    faHandHoldingHand,
    faHeart,
    faLandmark,
    faLocationDot,
    faPhone,
    faRoad,
    faRoadCircleCheck,
    faScrewdriverWrench,
    faSterlingSign,
    faTriangleExclamation,
);
dom.watch();

// Import cookie policy
import '../../src/Cms/assets/ts/cookie-policy.ts';

// Import used car search
import '../../src/App/assets/ts/used-car-search.ts';

// Import used car finance controls
import '../../src/App/assets/ts/used-car-finance.ts';

// Setup search
(function (document: Document) {
    function initialiseTooltips(): void {
        [...Object.values(document.querySelectorAll('[data-bs-toggle="tooltip"]'))].map((element: Element) => {
            (element as HTMLElement).onclick = (event) => event.preventDefault();
            return new Tooltip(element);
        });
    }

    document.addEventListener('click', function (event) {
        if (!event.target.matches('.anchor-click')) return;
        document.querySelector(event.target.dataset.target).scrollIntoView();
    }, false);

    document.addEventListener('DOMContentLoaded', () => {
        initialiseTooltips();
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-7BE7YF1CL0');
        var app = new Vue({
            el: '#editor-vue',
            data: {
                vrm: 'PX67 XPD',
                password: '',
                authorised:true,
                vehicle: null,
                vehicleData: null,
                techCategoryName: null,
                standardEquipmentCategoryName: null,
                emptyVehicleData: {
                    make: '',
                    model: '',
                    derivative: '',
                    body: '',
                    engine: '',
                    fuelType: '',
                    mpgCombined: '',
                    wltpC02Emissions: '',
                    techData: [],
                    standardEquipment: [],
                },
                showSaveMessage: false,
                showPasswordMessage: false,
            },
            methods: {
                search(){
                    let vue = this;
                    fetch('/used-car-editor-search?vrm='+vue.vrm)
                        .then(response => response.json())
                        .then(data => {
                            console.log(data);
                            vue.vehicle = data.vehicle;
                            if(data.vehicle_data !== null){
                                data.vehicle_data.standardEquipment = JSON.parse(data.vehicle_data.standardEquipment);
                                data.vehicle_data.techData = JSON.parse(data.vehicle_data.techData);
                            }
                            vue.vehicleData = data.vehicle_data !== null ? data.vehicle_data : vue.emptyVehicleData;
                        })
                        .catch(error => {

                        });
                },
                authorise(){
                    let vue = this;
                    fetch('/used-car-editor-authorise?password='+vue.password)
                        .then(response => response.json())
                        .then(data => {
                            console.log(data);
                            if(data.success == true){
                                vue.authorised = true;
                            } else{
                                vue.showPasswordMessage = true;
                                setTimeout(function(){
                                    vue.showPasswordMessage = false;
                                }, 3000)
                            }
                        })
                        .catch(error => {

                        });
                },
                addTechCategory(){
                    if(this.vehicleData.techData === null){
                        this.vehicleData.techData = [];
                    }
                    this.vehicleData.techData.push({
                        label: this.techCategoryName,
                        dataInput: '',
                        dataValueInput: '',
                        data: []
                    })
                    this.techCategoryName = '';
                },
                addEquipCategory(){
                    if(this.vehicleData.standardEquipment === null){
                        this.vehicleData.standardEquipment = [];
                    }
                    this.vehicleData.standardEquipment.push({
                        label: this.standardEquipmentCategoryName,
                        dataInput: '',
                        dataValueInput: '',
                        data: []
                    })
                    this.standardEquipmentCategoryName = '';
                },
                removeTechDataCategory(index){
                    this.vehicleData.techData.splice(index, 1);
                },
                removeStandardEquipmentCategory(index){
                    this.vehicleData.standardEquipment.splice(index, 1);
                },
                addStandardEquipment(index){
                    this.vehicleData.standardEquipment[index].data.push({
                        'label': this.vehicleData.standardEquipment[index].dataInput,
                        'value': this.vehicleData.standardEquipment[index].dataValueInput,
                    })
                    this.vehicleData.standardEquipment[index].dataInput = '';
                    this.vehicleData.standardEquipment[index].dataValueInput = '';
                },
                addTechData(index){

                    this.vehicleData.techData[index].data.push({
                        'label': this.vehicleData.techData[index].dataInput,
                        'value': this.vehicleData.techData[index].dataValueInput,
                    });
                    this.vehicleData.techData[index].dataInput = '';
                    this.vehicleData.techData[index].dataValueInput = '';
                },
                removeStandardEquipment(index, dataIndex){
                    this.vehicleData.standardEquipment[index].data.splice(dataIndex, 1);
                    this.vehicleData.standardEquipment[index].dataInput = '';
                },
                removeTechData(index, dataIndex){
                    this.vehicleData.techData[index].data.splice(dataIndex, 1);
                    this.vehicleData.techData[index].dataInput = '';
                },
                save(){
                    console.log('save data')
                    let vue = this;
                    fetch('/used-car-editor-save', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({'vehicle': vue.vehicle, 'vehicle_data': vue.vehicleData})
                    }).then(res => res.json())
                        .then(function(res){
                            console.log(res)
                            vue.showSaveMessage = true;
                            setTimeout(function(){
                                vue.showSaveMessage = false;
                            }, 3000)
                        });
                }
            }
        })
    });
})(document);
