import Offcanvas from 'bootstrap/js/dist/offcanvas';
import { getCookie, setCookie } from 'typescript-cookie';

// Set up the window object
type WindowWithDataLayer = Window & {
    dataLayer: unknown[];
};

declare const window: WindowWithDataLayer;

// Set up cookies policy controller
type CookieCategories = {
    analytics: boolean;
};

const COOKIE_NAME = 'cookiePolicyShown';

class CookiePolicyController {
    private readonly categories: CookieCategories = { analytics: false };

    constructor(
        private readonly offcanvas: Offcanvas,
        private readonly analyticsId: string,
        private readonly nonce: string | null,
    ) {
        const cookiePolicy: string | undefined = getCookie(COOKIE_NAME);
        if (!cookiePolicy) {
            offcanvas.show();
            return;
        }

        this.categories = JSON.parse(cookiePolicy);
        this.processCategories();
    }

    private processCategories(): void {
        if (this.categories.analytics) {
            this.enableAnalytics();
        }
    }

    private enableAnalytics(): void {
        // window.dataLayer = window.dataLayer || [];
        //
        // window.dataLayer.push('js', new Date());
        // window.dataLayer.push('config', this.analyticsId);
        //
        // const gaScript = document.createElement('script');
        // gaScript.async = true;
        // gaScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.analyticsId;
        // if (this.nonce !== null) {
        //     gaScript.nonce = this.nonce;
        // }
        //
        // document.head.appendChild(gaScript);
    }

    public acceptAll(): void {
        this.categories.analytics = true;
        this.dismiss();
    }

    public rejectAll(): void {
        this.categories.analytics = false;
        this.dismiss();
    }

    public setAnalytics(enabled: boolean): void {
        console.log(enabled);
        this.categories.analytics = enabled;
    }

    public dismiss(): void {
        this.processCategories();

        setCookie(COOKIE_NAME, JSON.stringify(this.categories));
        this.offcanvas.hide();
    }
}

// Prepare document
(function (document: Document) {
    function initialiseCookiePolicy(): void {
        const offcanvas = document.getElementById('cookiesOffcanvas') as HTMLInputElement | null;
        if (!offcanvas || !offcanvas.dataset.cookiesAnalyticsId) {
            return;
        }

        const controller = new CookiePolicyController(
            new Offcanvas(offcanvas),
            offcanvas.dataset.cookiesAnalyticsId,
            offcanvas.nonce ?? null,
        );
        const acceptAll = document.getElementById('cookiesAcceptAll') as HTMLInputElement | null;
        const rejectAll = document.getElementById('cookiesRejectAll') as HTMLInputElement | null;
        const toggleAnalytics = document.getElementById('cookiesToggleAnalytics') as HTMLInputElement | null;
        const dismiss = document.getElementById('cookiesDismiss') as HTMLInputElement | null;

        if (!acceptAll || !rejectAll || !toggleAnalytics || !dismiss) {
            return;
        }

        acceptAll.onclick = () => controller.acceptAll();
        rejectAll.onclick = () => controller.rejectAll();
        toggleAnalytics.onclick = () => controller.setAnalytics(toggleAnalytics.checked);
        dismiss.onclick = () => controller.dismiss();
    }

    document.addEventListener('DOMContentLoaded', () => {
        initialiseCookiePolicy();
    });
})(document);
