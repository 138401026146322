// Prepare document
(function (document: Document) {
    type ModelData = { displayValue: string; value: string };

    async function fetchListOfModelsForManufacturer(selectManufacturer: string): Promise<ModelData[]> {
        // Wait for api response
        const response = await (await fetch(`/api/v1/vehicles/models-for-make/${selectManufacturer}`)).json();

        // Return list of models
        return response.models;
    }

    function updateModelList(selectModelElement: HTMLSelectElement, modelList: ModelData[]): void {
        // Reset options
        selectModelElement.disabled = true;
        selectModelElement.options.length = 0;
        selectModelElement.options.add(new Option('Model (All)', undefined, true));

        // Check for a list of models
        if (modelList.length < 1) {
            return;
        }

        // Update model list
        selectModelElement.disabled = false;
        modelList.forEach((model) => selectModelElement.options.add(new Option(model.displayValue, model.value)));
    }

    function initialiseUsedCarSearch(): void {
        // Get select elements
        const selectMakeElement = document.getElementById('used-cars-select-make') as HTMLSelectElement;
        const selectModelElement = document.getElementById('used-cars-select-model') as HTMLSelectElement;
        if (!selectMakeElement || !selectModelElement) {
            return;
        }

        selectMakeElement.onchange = async () => {
            // Parse selected manufacturer
            const selectedManufacturer: string = selectMakeElement.value.toLowerCase();
            if (selectedManufacturer.length === 0 || !/^[a-z0-9. -]+$/.test(selectedManufacturer)) {
                return;
            }

            // Fetch list of models and update options
            updateModelList(selectModelElement, await fetchListOfModelsForManufacturer(selectedManufacturer));
        };
    }

    document.addEventListener('DOMContentLoaded', () => {
        initialiseUsedCarSearch();
    });
})(document);
